/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { ParsedUrlQuery } from 'querystring';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BillingAddressResponse } from '@components/CheckoutForm/types';
import { LoginLinkResponse } from '@components/RedirectRoute/types';
import { api, extractResponse } from '@utils/network';
import { addSecurityCheck } from '@utils/security';
import { InitRequest } from '@views/Payment/types';
import { Receipt } from '@views/Receipt/types';

export const Request = {
  initiatePayment: async (orderId: string): Promise<AxiosResponse<InitRequest, any>> =>
    await addSecurityCheck(api.post(`orders/${orderId}/initiate`)),
  verifyBillingAddress: (requestData: string, config?: AxiosRequestConfig): Promise<BillingAddressResponse> =>
    extractResponse<BillingAddressResponse>(api.post('billing_address', requestData, config)),
  receipt: (config?: AxiosRequestConfig): Promise<Receipt> => extractResponse<Receipt>(api.get('/receipt', config)),
  checkAuthStatus: async (): Promise<void> => await extractResponse<void>(api.get('/sessions')),
  getLoginLink: (config?: AxiosRequestConfig): Promise<LoginLinkResponse> =>
    extractResponse<LoginLinkResponse>(api.get('/oauth/link', config)),
  getOAuthTokens: (body: ParsedUrlQuery, config?: AxiosRequestConfig): Promise<void> =>
    extractResponse(api.post('/oauth/token', body, config)),
  logout: (config?: AxiosRequestConfig): Promise<{ redirectURL: string }> =>
    extractResponse(api.post('/oauth/logout', {}, config)),
};
