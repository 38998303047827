/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { InitOptions } from 'i18next';
import { Locale } from '@i18n/types';

export const DEFAULT_LOCALE_KEY: Locale = 'en-GB';

export const SUPPORTED_LOCALES: Locale[] = ['en-GB', 'de-DE', 'fr-FR', 'pt-BR'];

export const locConfig: InitOptions = {
  fallbackLng: DEFAULT_LOCALE_KEY,
  saveMissing: true,
  nsSeparator: '::',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
};

export const LOCALE_REGEX = /^\/(([a-zA-Z]{2})-([a-zA-Z]{2}))(?=(\/|$)){1}/;
export const LANGUAGE_REGEX = /^\/([a-zA-Z]{2})(?=(\/|$)){1}/;
