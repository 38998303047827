/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { noop } from '@babel/types';
import { useJds } from '@jagex-pp/jds';
import React, { FunctionComponent, Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { PageContent } from '@components/Layout/styled';
import { Loader } from '@components/Loader';
import { I18n } from '@i18n/i18n';
import { I18nFacade } from '@i18n/I18nFacade';
import { useStore } from '@stores/useStore';

export const LocaleWrapper: FunctionComponent<{ i18n: I18n }> = ({ i18n }) => {
  const { localisation } = useStore();
  const { lang } = useParams<{ lang: string }>();
  const navigate = useNavigate();
  const { setLocale } = useJds();
  const location = useLocation();

  useEffect(() => {
    i18n.detectUserLocale();

    const loadTranslation = async () => {
      const locale = await i18n.getTranslations();

      const redirectPath = I18nFacade.getPathWithCorrectLocale(location, locale);
      if (redirectPath) {
        navigate(redirectPath);
      }
      setLocale(locale);
      localisation.setLocalisationInstance(i18n);
    };
    loadTranslation().catch(noop);
    // eslint-disable-next-line
  }, [lang, i18n]);

  return (
    <Suspense
      fallback={
        <PageContent style={{ marginTop: '160px' }}>
          <Loader />
        </PageContent>
      }
    >
      <I18nextProvider i18n={i18n.i18nInstance}>
        <Outlet />
      </I18nextProvider>
    </Suspense>
  );
};
