/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { Location } from 'history';
import { TARGET_ROUTE_STORAGE_KEY } from '@common/constants';

export const setTargetRoute = ({ pathname, search }: Location): void => {
  sessionStorage.setItem(TARGET_ROUTE_STORAGE_KEY, `${pathname}${search}`);
};

export const getLastTargetRoute = (): string | null => sessionStorage.getItem(TARGET_ROUTE_STORAGE_KEY);
