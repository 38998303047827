/*
 * Copyright 1999-2024 Jagex Ltd.
 */
export const APP_ROOT_ID = 'root';
export const SECURITY_BLOCK_ID = 'pay__security-page-content';

export const API_URL = window._env_?.PAYMENTS_API_URL;

export enum StatusCode {
  // 2xx
  Ok = 200,
  NoContent = 204,
  // 3xx,
  Found = 302,
  TemporaryRedirect = 307,
  // 4xx
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  // 5xx
  InternalServerError = 500,
}

export const HEADER_CONTENT_TYPE = 'content-type';

export const HEADER_LOCALE = 'locale';

export enum ContentType {
  Html = 'text/html',
  Json = 'application/json',
}

export enum ZIndexes {
  SecurtiyIframe = 10000,
}

export const USA_CODE = 'US';

export const TARGET_ROUTE_STORAGE_KEY = 'targetRoute';

export enum AuthStatus {
  UNKNOWN,
  AUTHORIZED,
  UNAUTHORIZED,
}

export enum PaymentType {
  checkout = 'CHECKOUT_PAYMENT_METHOD',
  adyen = 'ADYEN_PAYMENT_METHOD',
}

export const RegexPatterns = {
  ADDRESS_FORMAT: /^[A-z0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u0152-\u0153\u1E9E\/'\s.,\-()&]+$/,
  POSTCODE_FORMAT: /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/,
} as const;
