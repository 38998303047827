/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { Header, Footer } from '@jagex-pp/jds';
import React, { FunctionComponent, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Request } from '@common/requests';
import { Loader } from '@components/Loader';
import { CurrentUser } from '@stores/domains/User/types';
import { Container, PageContent } from './styled';
import { DefferedPageParams } from './types';

const Error = lazy(() => import('@views/Error'));

export const DeferredPage: FunctionComponent<DefferedPageParams> = ({ children, data, loaderText, isWithError }) => {
  return (
    <Suspense fallback="">{isWithError ? <Error /> : data ? children : <Loader loaderText={loaderText} />}</Suspense>
  );
};

export const Layout: FunctionComponent<{ userInfo: CurrentUser }> = ({ userInfo }) => {
  const { t } = useTranslation();

  const logout = () => Request.logout().then(({ redirectURL }) => window.location.assign(redirectURL));

  return (
    <Container>
      <Header>
        <Header.Horizontal position="right">
          <Header.Dropdown
            button={
              <Header.AccountName
                displayName={userInfo?.displayName ?? t('header:account')}
                suffix={userInfo?.suffix ?? ''}
              />
            }
            id="account-settings-menu"
          >
            <Header.DropdownItem uid="1" onClick={logout}>
              {t('header:logout')}
            </Header.DropdownItem>
          </Header.Dropdown>
        </Header.Horizontal>
        <Header.Vertical>
          <Header.VerticalItem>
            <Header.Button onClick={logout}>{t('header:logout')}</Header.Button>
          </Header.VerticalItem>
        </Header.Vertical>
      </Header>

      <PageContent>
        <Outlet />
      </PageContent>

      <Footer />
    </Container>
  );
};
