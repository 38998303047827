/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { AxiosInstance } from 'axios';
import i18n, { i18n as i18nextType } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { HEADER_LOCALE } from '@common/constants';
import { DEFAULT_LOCALE_KEY } from '@i18n/config';
import { I18nFacade } from './I18nFacade';
import { Locale } from './types';

export class I18n {
  api: AxiosInstance;
  i18nInstance: i18nextType;
  private locale: Locale;
  private localeHeaderInterceptor: number;

  constructor(api: AxiosInstance) {
    this.i18nInstance = i18n.use(initReactI18next);
    this.api = api;
  }

  detectUserLocale(): Locale {
    this.locale = I18nFacade.detectUserLocale() || DEFAULT_LOCALE_KEY;
    return this.locale;
  }

  async getTranslations(): Promise<Locale> {
    const cfg = await I18nFacade.getTranslations(this.api, this.locale);
    await this.i18nInstance.init(cfg);
    this.setAPILocaleHeader();
    return cfg.lng;
  }

  getSelectedLanguage(): string {
    return this.i18nInstance.language;
  }

  saveCurrentLocale(): void {
    I18nFacade.saveCurrentLocale(this.i18nInstance.language);
  }

  setAPILocaleHeader(): void {
    if (this.localeHeaderInterceptor) {
      this.api.interceptors.request.eject(this.localeHeaderInterceptor);
    }

    this.localeHeaderInterceptor = this.api.interceptors.request.use(
      (config) => {
        config.headers[HEADER_LOCALE] = this.getSelectedLanguage();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}
