/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { parse, ParsedUrlQuery } from 'querystring';
import { useMemo, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse the query strins.
// TODO: add generic like `<R extends Record<string, string | string[]>>(): R`
export const useQuery = (): ParsedUrlQuery => {
  const { search } = useLocation();
  const normalizedSearch = search.replace(/^\?/, '');

  return useMemo(() => parse(normalizedSearch), [normalizedSearch]);
};

export const useFragment = (): ParsedUrlQuery => {
  const { hash } = useLocation();
  const normalizedFragment = hash.replace(/^#/, '');

  return useMemo(() => parse(normalizedFragment), [normalizedFragment]);
};

type AccordionHook = [string | number, (id: string | number) => void];
export const useAccordion = (initialExpanded: string | number = ''): AccordionHook => {
  const [expandedItem, setExpandedItem] = useState(initialExpanded);

  const toggleMethod = useCallback(
    (name: string) => setExpandedItem(name === expandedItem ? '' : name),
    [expandedItem]
  );

  return [expandedItem, toggleMethod];
};
