/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { ContentFrame, Loader as JDSLoader } from '@jagex-pp/jds';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './styled';
import { LoaderParams } from './types';

export const Loader: FunctionComponent<LoaderParams> = ({ loaderText }) => {
  const { t } = useTranslation();

  return (
    <ContentFrame>
      <Container>
        <JDSLoader />
        <div>{loaderText ? t(loaderText) : ''}</div>
      </Container>
    </ContentFrame>
  );
};
