/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { injectable } from 'inversify';
import { action, observable, makeObservable, computed } from 'mobx';
import { Requests } from './Requests';
import { CurrentUser } from './types';

@injectable()
export class User {
  info: CurrentUser | null = null;

  constructor() {
    makeObservable(this, {
      info: observable,
      setUserInfo: action,
      nameWithSuffix: computed,
    });
  }

  get nameWithSuffix() {
    return this.info?.displayName && this.info?.suffix ? `${this.info?.displayName}#${this.info?.suffix}` : '';
  }

  setUserInfo(info: CurrentUser): void {
    this.info = info;
  }

  async current(): Promise<void> {
    const userInfo = await Requests.current();
    this.setUserInfo(userInfo);
  }
}
