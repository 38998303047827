/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { withHeadersFilter } from '@jagex/configuration/lib/filterHeaders';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { API_URL, StatusCode } from '@common/constants';
import { addSecurityCheck, NetworkError, HttpResponseNetworkError } from '@utils/security';

export type RequestConfig = AxiosRequestConfig;

export const api: AxiosInstance = withHeadersFilter(
  axios.create({
    baseURL: API_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': 'x-requested-with, content-type',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
);

export const extractResponse = async <T>(request: Promise<AxiosResponse<T>>): Promise<T> => {
  const response = await addSecurityCheck(request);

  return response.data;
};

// Simplifying this check since we need only data usually.
export const isAxiosResponse = (obj: Record<string, any>): obj is AxiosResponse => Boolean(obj.data);

export const isHttpResponseNetworkError = (error?: NetworkError | any): error is HttpResponseNetworkError =>
  error?.errorType === 'httpResponse';

export const extractAxiosErrorResponse = (error: AxiosError | any): AxiosResponse | any => error?.response ?? error;

/**
 * Status extractor. If status exists in response (if response even exists), use it, otherwise InternalServerError (500)
 * @param response
 */
export const extractErrorStatus = (response?: AxiosResponse<NetworkError> | any): number => {
  if (!isAxiosResponse(response)) {
    return response.status;
  }

  if (isHttpResponseNetworkError(response.data)) {
    const { data } = response.data;

    return data.status ?? StatusCode.InternalServerError;
  }

  return response.status ?? StatusCode.InternalServerError;
};
