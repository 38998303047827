/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { container } from './config';
import { Localisation, User } from './domains';
import { TYPES } from './types';

export type Store = {
  user: User;
  localisation: Localisation;
};

export const createStore = (): Store => {
  const localisation = container.get<Localisation>(TYPES.Localisation);
  const user = container.get<User>(TYPES.User);

  return {
    localisation,
    user,
  };
};
